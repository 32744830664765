import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import Frame from "../components/Frame"
import Svg from "../components/Svg"

const Video = React.forwardRef(({
  src,
  autoplay,
  controls,
  crossorigin,
  loop,
  muted,
  playsinline,
  poster,
  curtain,
  preload,
  loading,
  title,
  mediaSessionMetaData,
  startAt,
  width,
  height,
  onCanPlay,
  onCanPlayThrough,
  onChange,
  onEnded,
  onError,
  onLoadStart,
  onLoadedData,
  onLoadedMetadata,
  onPause,
  onPlay,
  onPlaying,
  onProgress,
  onSeeked,
  onSeeking,
  onTimeUpdate,
  onVolumeChange,
  ...props
}, ref) => {

  // Constructors and Lifecycle
  // --------------------------
  // 01. The first argument is fired after browser layout and paint. The
  //     second argument is an array of values, which when empty only fires
  //     once, making it similar to the componentDidMount() method.
  // 02. When you return a function in the callback of useEffect(), the
  //     returned function will be called before the component is removed,
  //     making it similar to the componentWillUnmount() method.

  const elVideo                 = useRef(null)
  const [showtime, setShowtime] = useState(false)

  useEffect(() => { // 01

  }, [])

  useEffect(() => { // 02
    return () => {

    }
  }, [])


  // Sources
  // -------

  const sources = (src) => {
    let srcClean  = typeof src === 'string' ? [src] : src
    let srcOutput = []

    srcClean.map(el => {
      let ext = el.split('?').shift().split('.').pop()
      ext = el.includes('vimeo') ? 'mp4' : ext
      return srcOutput.push(<source key={el} src={el} type={`video/${ext}`}/>)
    })

    return srcOutput
  }


  // Events
  // ------

  const onClickCurtain = () => {
    const theRef = ref || elVideo
    setShowtime(true)
    theRef.current.play()
  }

  const onEndedLocal = () => {
    if ( onEnded ) {
      onEnded()
    }
    if ( curtain ) {
      setShowtime(false)
    }
  }

  const onLoadedMetadataLocal = () => {
    if ( onLoadedMetadata ) {
      onLoadedMetadata()
    }
    if ( startAt !== null ) {
      const theRef = ref || elVideo
      theRef.current.currentTime = startAt
    }
  }

  const onPlayLocal = () => {
    if ( onPlay ) {
      onPlay()
    }
    if (mediaSessionMetaData && "mediaSession" in navigator) {
      navigator.mediaSession.metadata = new window.MediaMetadata(mediaSessionMetaData)
    }
  }

  const onPlayingLocal = () => {
    if ( onPlaying ) {
      onPlaying()
    }
    if ( curtain ) {
      setTimeout(() => {
        setShowtime(true)
      }, 1000)
    }
  }


  // Output
  // ------
  // autoPictureInPicture    = {false}
  // disablePictureInPicture = {true}

  return (
    <div className="hcc-video" {...props}>
      <Frame width={width} height={height}>
        {curtain &&
          /* eslint-disable-next-line */
          <a className={`hcc-video-curtain${showtime ? ' is-showtime' : ''}`} style={{backgroundImage : `url(${curtain})`}} onClick={onClickCurtain}>
            <Svg type="play-circle"/>
          </a>
        }
        {/* eslint-disable-next-line */}
        <video
          autoPlay         = {autoplay}
          controls         = {controls}
          crossOrigin      = {crossorigin}
          loop             = {loop}
          muted            = {muted}
          playsInline      = {playsinline}
          poster           = {poster}
          preload          = {preload}
          loading          = {loading}
          title            = {title}
          width            = {width}
          height           = {height}
          onCanPlay        = {onCanPlay}
          onCanPlayThrough = {onCanPlayThrough}
          onChange         = {onChange}
          onEnded          = {onEndedLocal}
          onError          = {onError}
          onLoadStart      = {onLoadStart}
          onLoadedData     = {onLoadedData}
          onLoadedMetadata = {onLoadedMetadataLocal}
          onPause          = {onPause}
          onPlay           = {onPlayLocal}
          onPlaying        = {onPlayingLocal}
          onProgress       = {onProgress}
          onSeeked         = {onSeeked}
          onSeeking        = {onSeeking}
          onTimeUpdate     = {onTimeUpdate}
          onVolumeChange   = {onVolumeChange}
          ref              = {ref || elVideo}
        >
          {sources(src)}
          {/* Sorry, but it appears your browser doesn't support embedded videos. To view this content, please try another browser. */}
        </video>
      </Frame>
    </div>
  )
})

Video.defaultProps = {
  src                  : null,
  autoplay             : false,
  controls             : true,
  crossorigin          : "anonymous",
  loop                 : false,
  muted                : false,
  playsinline          : false,
  poster               : null,
  curtain              : null,
  preload              : "none",
  loading              : "eager",
  title                : null,
  mediaSessionMetaData : null,
  startAt              : null,
  width                : null,
  height               : null,
  onCanPlay            : null,
  onCanPlayThrough     : null,
  onChange             : null,
  onEnded              : null,
  onError              : null,
  onLoadStart          : null,
  onLoadedData         : null,
  onLoadedMetadata     : null,
  onPause              : null,
  onPlay               : null,
  onPlaying            : null,
  onProgress           : null,
  onSeeked             : null,
  onSeeking            : null,
  onTimeUpdate         : null,
  onVolumeChange       : null,
}

Video.propTypes = {
  src                  : PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  autoplay             : PropTypes.bool,
  controls             : PropTypes.bool,
  crossorigin          : PropTypes.string,
  loop                 : PropTypes.bool,
  muted                : PropTypes.bool,
  playsinline          : PropTypes.bool,
  poster               : PropTypes.string,
  curtain              : PropTypes.string,
  preload              : PropTypes.string,
  loading              : PropTypes.string,
  title                : PropTypes.string,
  mediaSessionMetaData : PropTypes.object,
  startAt              : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width                : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height               : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onCanPlay            : PropTypes.func,
  onCanPlayThrough     : PropTypes.func,
  onChange             : PropTypes.func,
  onEnded              : PropTypes.func,
  onError              : PropTypes.func,
  onLoadStart          : PropTypes.func,
  onLoadedData         : PropTypes.func,
  onLoadedMetadata     : PropTypes.func,
  onPause              : PropTypes.func,
  onPlay               : PropTypes.func,
  onPlaying            : PropTypes.func,
  onProgress           : PropTypes.func,
  onSeeked             : PropTypes.func,
  onSeeking            : PropTypes.func,
  onTimeUpdate         : PropTypes.func,
  onVolumeChange       : PropTypes.func,
}

export default Video
