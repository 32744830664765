import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Button from "../components/Button"
import Container from "../components/Container"
import Doom from "../components/Doom"
import Hero from "../components/Hero"
import Img from "../components/Img"
import Section from "../components/Section"
import Site from "../components/Site"
import Tile from "../components/Tile"
import Tiles from "../components/Tiles"
import Video from "../components/Video"
import { formatLink } from "../helpers"
import "../css/index.scss"

const HomePage = () => (
  <StaticQuery
    render={data => {
      const homeData     = data.allDatoCmsHome.edges[0].node
      const heroImg      = homeData.hasCustomHeroImage && homeData.heroImage !== null ? { url : homeData.heroImage.url, position : homeData.heroImageObjectPosition } : { url : '/home/bg-hero-01.jpg', position : '72% 50%' }
      const heroMedia    = homeData.hasCustomHeroVideo && !! homeData.heroVideo ? { video : homeData.heroVideo } : heroImg
      const allTalks     = [...data.allDatoCmsTalk.edges].map(el => el.node)
      const allTiles     = [...data.allDatoCmsHomeTile.edges].map(el => el.node)
      const latestSermon = [...allTalks].find(theTalk => theTalk.presentation.slug === 'sermons')

      return (
        <Site className="hcc-home">
          <article>
            <Hero
              type      = "bubble"
              content   = "stretch"
              className = "hcc-home-intro"
              arrow     = {true}
              bg        = {[
                {
                  opacity : 0.33,
                  stack   : [
                    { angle : 'to bottom right', stops : ['#000', '#000'] },
                  ]
                },
                {
                  stack  : [
                    heroMedia,
                  ]
                },
                {
                  stack : [
                    { angle : 'to bottom right', stops : ['#000', '#000'] },
                  ]
                },
              ]}
            >
              <Doom tag="h1" content={homeData.title}/>
              {homeData.subtitle !== '' &&
                <Doom tag="p" content={homeData.subtitle}/>
              }
              <div className="hcc-hero-cta break-sm">
                <Button label={homeData.primaryButtonLabel} href={homeData.primaryButtonLink} className="is-primary has-no-outline"/>
                {homeData.hasSecondaryButton &&
                  <Button label={homeData.secondaryButtonLabel} href={homeData.secondaryButtonLink} className="is-secondary has-no-outline"/>
                }
              </div>
              {/*
              <Video
                src         = {homeData.heroVideo}
                autoplay    = {true}
                controls    = {false}
                loop        = {true}
                muted       = {true}
                preload     = "none"
                loading     = "lazy"
                playsinline = {true}
              />
              */}
            </Hero>
            <Section className="hcc-home-tiles">
              <Container size="hero">
                <Tiles>
                  {allTiles.map((el, i) => {
                    if ( ! el.display ) {
                      return false
                    }

                    return (
                      <Tile
                        key         = {`home-tile-${el.title}-${i}`}
                        size        = "custom"
                        cardinality = "n"
                        href        = {el.link}
                        title       = {el.title}
                        subtitle    = {el.subtitle}
                        cta         = {[{ "href" : el.link, "label" : el.callToAction }]}
                        style       = {{
                          "--c-tile-bg"     : `rgba(${el.colorBg.red}, ${el.colorBg.green}, ${el.colorBg.blue}, ${el.colorBg.alpha})`,
                          "--c-tile-head"   : `rgba(${el.colorTextStrong.red}, ${el.colorTextStrong.green}, ${el.colorTextStrong.blue}, ${el.colorTextStrong.alpha})`,
                          "--c-tile-accent" : `rgba(${el.colorAccent.red}, ${el.colorAccent.green}, ${el.colorAccent.blue}, ${el.colorAccent.alpha})`,
                          "--c-tile"        : `rgba(${el.colorTextSubtle.red}, ${el.colorTextSubtle.green}, ${el.colorTextSubtle.blue}, ${el.colorTextSubtle.alpha})`,
                          "--c-tile-a"      : `rgba(${el.colorTextStrong.red}, ${el.colorTextStrong.green}, ${el.colorTextStrong.blue}, ${el.colorTextStrong.alpha})`,
                        }}
                      >
                        <Img
                          src     = {el.image.url}
                          width   = {el.image.width}
                          height  = {el.image.height}
                          alt     = {`Featured image for ${el.title}`}
                          loading = "lazy"
                          retina  = {true}
                        />
                      </Tile>
                    )
                  })}
                </Tiles>
              </Container>
            </Section>
            <Section className="hcc-home-video">
              <Container size="hero">
                <Tiles>
                  <Tile
                    size        = "1/1"
                    cardinality = "sw"
                    href        = {formatLink(latestSermon.slug, 't')}
                    category    = "Latest Sermon"
                    title       = {latestSermon.title}
                    subtitle    = ""
                    cta         = {false}
                  >
                    <Video
                      src         = {latestSermon.video}
                      autoplay    = {true}
                      controls    = {false}
                      loop        = {true}
                      muted       = {true}
                      preload     = "none"
                      loading     = "lazy"
                      playsinline = {true}
                      startAt     = {20}
                    />
                  </Tile>
                </Tiles>
              </Container>
            </Section>
          </article>
        </Site>
      )
    }}
    query={graphql`
      query HomeQuery {
        allDatoCmsHome {
          edges {
            node {
              title
              subtitle
              primaryButtonLabel
              primaryButtonLink
              hasSecondaryButton
              secondaryButtonLabel
              secondaryButtonLink
              hasCustomHeroImage
              heroImage {
                width
                height
                url
              }
              heroImageObjectPosition
              hasCustomHeroVideo
              heroVideo
            }
          }
        }
        allDatoCmsHomeTile(sort: {order: ASC, fields: position}) {
          edges {
            node {
              display
              position
              title
              subtitle
              callToAction
              link
              image {
                width
                height
                url
              }
              colorTextStrong {
                red
                blue
                green
                alpha
              }
              colorTextSubtle {
                red
                blue
                green
                alpha
              }
              colorBg {
                red
                blue
                green
                alpha
              }
              colorAccent {
                red
                blue
                green
                alpha
              }
            }
          }
        }
        allDatoCmsTalk(
          sort : {fields : date, order : DESC}
        ) {
          edges {
            node {
              ...TalkFragment
            }
          }
        }
      }
    `}
  />
)

export default HomePage
