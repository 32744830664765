import React from "react"
import PropTypes from "prop-types"

const Frame = ({width, height, children, ...props}) => {
  let style = {}

  if ( width !== null ) {
    style['--frame-ar-width'] = width
  }

  if ( height !== null ) {
    style['--frame-ar-height'] = height
  }

  return (
    <div className="hcc-frame" style={style} {...props}>
      {children}
    </div>
  )
}

Frame.defaultProps = {
  width    : null,
  height   : null,
  children : null,
}

Frame.propTypes = {
  width    : PropTypes.number,
  height   : PropTypes.number,
  children : PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default Frame
