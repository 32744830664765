import React from "react"
import PropTypes from "prop-types"
import Bg from '../components/Bg'
import Container from "../components/Container"
import Section from '../components/Section'
import Svg from '../components/Svg'

const Hero = ({type, content, tag, container, bg, arrow, className, children, ...props}) => {

  const classNames = ['hcc-hero', 'hcc-grid', `is-${type}`, `is-${content}`]

  if ( className ) {
    classNames.push(className)
  }

  return (
    <Section tag={tag} className={classNames.join(' ')} {...props}>
      <Container size={container} className="hcc-hero-content hcc-flex">
        {children}
      </Container>
      <Bg layers={bg}/>
      {arrow && <i className="hcc-hero-arrow"><Svg type="rarr"/></i>}
    </Section>
  )

}

Hero.defaultProps = {
  type      : 'bubble',
  content   : 'stretch',
  tag       : 'section',
  container : 'xl',
  bg        : null,
  arrow     : false,
  className : '',
  children  : null,
}

Hero.propTypes = {
  type      : PropTypes.string,
  content   : PropTypes.string,
  tag       : PropTypes.string,
  container : PropTypes.string,
  bg        : PropTypes.array,
  arrow     : PropTypes.bool,
  className : PropTypes.string,
  children  : PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default Hero
