import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { BlurhashCanvas } from "react-blurhash"
import Video from "../components/Video"

const Bg = ({layers, children, ...props}) => {

  // Constructors and Lifecycle
  // --------------------------

  const elBg                          = useRef(null)
  const elVideo                       = useRef(null)
  const [videoLoaded, setVideoLoaded] = useState(false)


  // Layers
  // ------

  const renderLayerStyle = (layer, i) => {
    let theIndex       = (i + 1) * -1
    let theBlend       = layer.hasOwnProperty('blend')     ? layer.blend     : null
    let theBackgrounds = layer.hasOwnProperty('stack')     ? layer.stack     : null
    let theOpacity     = layer.hasOwnProperty('opacity')   ? layer.opacity   : null
    let theTransform   = layer.hasOwnProperty('transform') ? layer.transform : null
    let theFilter      = layer.hasOwnProperty('filter')    ? layer.filter    : null

    if ( theBackgrounds !== null ) {
      theBackgrounds = theBackgrounds.map(item => {

        if ( item.hasOwnProperty('stops') ) {
          let theAngle    = item.hasOwnProperty('angle')    ? item.angle    : 'to bottom'
          let theSize     = item.hasOwnProperty('size')     ? item.size     : 'cover'
          let theRepeat   = item.hasOwnProperty('repeat')   ? item.repeat   : 'no-repeat'
          let thePosition = item.hasOwnProperty('position') ? item.position : '50%'

          return `linear-gradient(${theAngle}, ${item.stops.join(', ')}) ${thePosition} / ${theSize} ${theRepeat}`
        }

        if ( item.hasOwnProperty('url') ) {
          let theSize       = item.hasOwnProperty('size')       ? item.size       : 'cover'
          let theRepeat     = item.hasOwnProperty('repeat')     ? item.repeat     : 'no-repeat'
          let thePosition   = item.hasOwnProperty('position')   ? item.position   : '50%'
          let theAttachment = item.hasOwnProperty('attachment') ? item.attachment : 'scroll'

          return `url(${item.url}) ${thePosition} / ${theSize} ${theRepeat} ${theAttachment}`
        }

        return false

      }).join(', ')
    }

    return {
      zIndex              : theIndex,
      background          : theBackgrounds,
      backgroundBlendMode : theBlend,
      opacity             : theOpacity,
      transform           : theTransform,
      filter              : theFilter,
    }
  }

  const setVideoStyle = (poster = null) => {
    let hasPoster = poster !== null

    return {
      backgroundImage : hasPoster && `url(${poster})`,
      // opacity         : videoLoaded ? 1 : 0,
      opacity         : videoLoaded ? 1 : 1,
    }
  }


  // Events
  // ------

  const onCanPlayThrough = () => {
    setTimeout(() => {
      if ( ! videoLoaded ) {
        console.log('<Bg/> onCanPlayThrough')
        setVideoLoaded(true)
      }
    }, 0)
  }


  // Output
  // ------

  return (
    <div className="hcc-bg" aria-hidden="true" ref={elBg} {...props}>
      {layers && layers.map((theLayer, i) =>

        <div key={`hcc-bg-layer-${i}`} className={`hcc-bg-layer-${i + 1}`} style={renderLayerStyle(theLayer, i)}>
          {theLayer.children && theLayer.children}
          {(theLayer.hasOwnProperty('stack') && theLayer.stack[0].hasOwnProperty('video')) &&
            <React.Fragment>
              <Video
                src              = {theLayer.stack[0].video}
                autoplay         = {true}
                controls         = {false}
                loop             = {true}
                muted            = {true}
                preload          = "none"
                loading          = "lazy"
                playsinline      = {true}
                ref              = {elVideo}
                style            = {setVideoStyle(null)}
                onCanPlayThrough = {onCanPlayThrough}
              />
            </React.Fragment>
          }
          {theLayer.hasOwnProperty('hash') &&
            <BlurhashCanvas hash={theLayer.hash} width={32} height={32} punch={1}/>
          }
        </div>

      )}
    </div>
  )

}


// Notes on `layers`
// -----------------
// 01. Gradient
//     - angle : 'to bottom'
//     - stops : ['rgba(0, 0, 0, 0.75)', 'var(--c-base-bg)']
// 
// 02. Image
//     - url        : 'https://picsum.photos/g/1800/1000?image=1042',
//     - size       : 'cover',
//     - repeat     : 'no-repeat',
//     - position   : '50%',
//     - attachment : 'scroll',
//
// 03. Video
//     - video : 'https://example.com/video.m4v',

Bg.propTypes = {
  layers : PropTypes.array,
}

Bg.defaultProps = {
  layers : null,
}

export default Bg
